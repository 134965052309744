import FirstViewEndObserver from "../extended_class/FirstViewEndObserver";
import GET_ARTICLES from "../class/getArticles";

const [d,header,mainvisual,mq] = [document,document.querySelector('.rwd002-header'),document.querySelector('.mainvisual'),window.matchMedia( "(max-width: 1023px)" ) ];

class FirstViewEndObserverHome extends FirstViewEndObserver {
  constructor (opt) { super(opt) }

  intersectIn () {
    header.classList.add('is-home-first-view');
    mainvisual.classList.add('is-home-first-view');
  }
  intersectOut () {
    header.classList.remove('is-home-first-view');
    mainvisual.classList.remove('is-home-first-view');
  }
}

class Blocks
{
  constructor()
  {
    this.init();
  }

  init()
  {
    this.news('.block-news__list[data-plugin="getArticles"]');
  }

  news(selector)
  {
    const news_block = new GET_ARTICLES(selector);
    news_block.makeItem = (item,content) => `
    <li class="block-${content}-item">
      <a href="/${content}/?mode=detail&amp;article=${item.id}" class="block-${content}-item__link">
        <div class="block-${content}-item__body">
          <div class="date-cate">
            <span class="date">${item.date}</span><span class="cate">${item.category.name}</span>
          </div>
          <h4 class="title ${item.is_new? 'is-new':''}">${(mq.matches)? item.title.str_truncate(24) : item.title.str_truncate(40)}</h4>
        </div>
      </a>
    </li>
    `;
    // news_block.makeItem = (item) => console.log(item);
    news_block.render();
  }
  fetchCategories (content) {
    return fetch(`/assets/api/getCategories/?content=${content}`)
      .then(r => r.json())
      .catch(e => { throw new Error(e)});
  }

  fetchArticles (opt){
    return fetch(`/assets/api/getArticles/?${ new URLSearchParams(opt)}`)
    .then(r => r.json())
    .catch(e => { throw new Error(e)});
  }
}

const domReady = TPL =>
{
  header.classList.add('is-home-first-view');
  new FirstViewEndObserverHome;
  let blocks = new Blocks;
};

// const domReady = TPL => false;

const jqInit = () => false;

export default function HOME () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );

  $(() => jqInit());
}